import { HttpEvent, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NotifyService } from '../common/services/notify.service';
import { AppState } from '../store';
import { logout } from '../account/store/account.actions';
import { AuthService } from '../common/services/auth.service';

const UNAUTHORIZED_URL_EXCEPTIONS = ['/oauth/token', '/auth/auth0/initiate', '/new_email_confirmation'];

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private notifyService: NotifyService,
    private translate: TranslateService,
    protected store: Store<AppState>,
    private authService: AuthService,
  ) {}
  // eslint-disable-next-line class-methods-use-this
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const overrideAuthHeader = req.headers.get('Authorization');
    // eslint-disable-next-line no-param-reassign
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/vnd.xplenty+json; version=2',
        Authorization: overrideAuthHeader != null ? overrideAuthHeader : `Bearer ${this.authService.getToken()}`,
      },
    });

    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 400: // Bad Request: The request was invalid. An accompanying error message will explain why.
              break;
            case 401: // Unauthorized: You are attempting to access the API with invalid credentials.
              if (UNAUTHORIZED_URL_EXCEPTIONS.find((url) => err.url.includes(url))) {
                break;
              } else {
                this.store.dispatch(logout({ shouldRedirect: true }));
                return new Observable<HttpEvent<any>>((subscriber) => subscriber.complete());
              }
            case 402: // Payment Required: You must confirm your billing info to use this API.
              this.notifyService.error(this.translate.instant(`response.${err.status}.message`));
              break;
            case 403: // Forbidden: The request has been refused. An accompanying error message will explain why.
              this.notifyService.error(this.translate.instant(`response.${err.status}.message`));
              break;
            case 404: // Not Found: The URI requested is invalid or the resource requested does not exist.
              if (req.headers.get('ignorenotify')) {
                break;
              }
              this.notifyService.error(this.translate.instant(`response.${err.status}.resource_not_found_message`));
              break;
            case 406: // Not Acceptable: The requested mime-type is not acceptable.
            case 415: // Unsupported Media Type: The specified media type is not supported.
              // this.notifyService.error(this.translate.instant("err."+err.status+".message"));
              break;
            case 422: // Unprocessable Entity: You have sent invalid fields.
              if (req.headers.get('ignorenotify')) {
                break;
              }
              if (err.error.message && err.error.message.includes('flow_type')) {
                this.notifyService.error(
                  null,
                  this.translate.instant(`response.${err.status}.message_wrong_flow_type`),
                );
                break;
              }

              // eslint-disable-next-line no-case-declarations
              const params = { message: '' };
              if (err.error.message) {
                params.message = err.error.message;
              }

              this.notifyService.error(
                params.message,
                this.translate.instant(`response.${err.status}.message`, params),
              );
              break;
            case 429: // Too Many Requests: The request exceeded the rate limitations.
            case 500: // Internal Server Error: An internal error occurred in the request.
              // this.notifyService.error(this.translate.instant(`response.${err.status}.message`));
              break;
            case 502: // Bad Gateway: Xplenty is down or being upgraded.
            case 503: // Service Unavailable: The Xplenty servers are up, but overloaded with requests. Try again later.
              this.notifyService.error(this.translate.instant(`response.${err.status}.message`));
              break;
            case -1: // Request timed out
              break;
            default:
              break;
          }
        }

        return throwError(err);
      }),
    );
  }
}
