import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AmazonRedshiftDestinationComponentData,
  BigQueryDestinationComponentData,
  DatabaseDestinationComponentData,
  GoogleSpannerDestinationComponentData,
  MongoDestinationComponentData,
  SnowflakeDestinationComponentData,
} from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { ControlContainer, NgForm } from '@angular/forms';

export type DatabaseDestinationData =
  | AmazonRedshiftDestinationComponentData
  | BigQueryDestinationComponentData
  | DatabaseDestinationComponentData
  | MongoDestinationComponentData
  | SnowflakeDestinationComponentData
  | GoogleSpannerDestinationComponentData;

@Component({
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  selector: 'database-destination',
  template: `
    <div class="database-destination">
      <div class="row">
        <div class="col-md-12">
          <xp-form-group *ngIf="hasWarehouse" class="col-md-6">
            <label for="warehouse_name">{{ 'database-destination.labels.warehouse_name' | translate }}</label>
            <xp-input
              type="text"
              class="form-control"
              name="warehouse_name"
              id="warehouse_name"
              [ngModel]="rawComponent.warehouse"
              (ngModelChange)="onValueChange($event, 'warehouse')"
              [placeholder]="'database-destination.placeholders.warehouse_name' | translate"
            ></xp-input>
            <small>Leave empty to use the connection’s default warehouse.</small>
          </xp-form-group>
          <xp-form-group *ngIf="hasDatabase" class="col-md-6">
            <label for="database_name">{{ 'database-destination.labels.database_name' | translate }}</label>
            <xp-input
              type="text"
              class="form-control"
              name="database_name"
              id="database_name"
              [ngModel]="rawComponent.database"
              (ngModelChange)="onValueChange($event, 'database')"
              [placeholder]="'database-destination.placeholders.database_name' | translate"
            ></xp-input>
            <small>Leave empty to use the connection’s default database.</small>
          </xp-form-group>
          <div class="row">
            <database-schema-input
              [rawComponent]="rawComponent"
              [component]="component"
              [hasSchemaByAccessMode]="true"
              [hasSchema]="hasSchema"
              [hasTableSchemaSelect]="hasTableSchemaSelect"
            ></database-schema-input>
          </div>
          <div class="row">
            <database-table-input
              [rawComponent]="rawComponent"
              [component]="component"
              [hasTable]="hasTable"
              [hasTableByAccessMode]="true"
              [hasCollection]="false"
              [hasTableSchemaSelect]="hasTableSchemaSelect"
              [tableNameHintText]="tableNameHintText"
              [showCreateTableTooltip]="hasCreateTable && rawComponent.create_table"
              [autoCompleteDescription]="tableAutocompleteDescription"
              [hideErrorMessage]="hideErrorMessageTable"
            ></database-table-input>
          </div>
          <div class="row" *ngIf="hasCreateTable || hasCreateColumns">
            <div class="col-sm-12" *ngIf="hasCreateTable">
              <xp-input-checkbox
                name="create_table"
                [ngModel]="rawComponent.create_table"
                (ngModelChange)="onValueChange($event, 'create_table')"
                [labelText]="'database-destination.labels.create_table' | translate"
              ></xp-input-checkbox>
            </div>
            <div class="col-sm-12" *ngIf="hasCreateColumns">
              <xp-input-checkbox
                name="add_columns"
                [ngModel]="rawComponent.add_columns"
                (ngModelChange)="onValueChange($event, 'add_columns')"
                [labelText]="'database-destination.labels.add_columns' | translate"
              ></xp-input-checkbox>
            </div>
          </div>
          <hr *ngIf="hasOperationType || hasPreSQL || hasPostSQL" />
          <div class="row">
            <xp-form-group *ngIf="hasOperationType" [validationDisabled]="true" class="col-md-6">
              <label for="operation_type">{{ 'database-destination.labels.operation_type' | translate }}</label>
              <xp-select
                [value]="rawComponent.operation_type"
                [options]="operationTypes"
                [preventEmpty]="true"
                name="operation_type"
                id="operation_type"
                (valueChange)="onValueChange($event, 'operation_type')"
                class="form-control xp-select"
              ></xp-select>
            </xp-form-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <xp-form-group *ngIf="hasPreSQL">
            <label for="pre_action_sql">{{ 'database-destination.labels.where_clause_pre' | translate }}</label>
            <code-editor
              [value]="rawComponent.pre_action_sql"
              [options]="bodyDataOptionsPre"
              name="body"
              (valueChange)="onValueChange($event, 'pre_action_sql')"
            ></code-editor>
            <small>SQL statements to execute before data is inserted to the target table.</small>
          </xp-form-group>
          <xp-form-group *ngIf="hasPostSQL">
            <label for="where_clause">{{ 'database-destination.labels.where_clause_post' | translate }}</label>
            <code-editor
              [value]="rawComponent.post_action_sql"
              [options]="bodyDataOptionsPost"
              name="body"
              (valueChange)="onValueChange($event, 'post_action_sql')"
            ></code-editor>
            <small>SQL statements to execute after data is inserted to the target table.</small>
          </xp-form-group>
        </div>
      </div>
    </div>
  `,
})
export class DatabaseDestinationComponent implements OnInit, OnChanges {
  @Input() rawComponent: DatabaseDestinationData;
  @Input() component: ComponentTypeItem;
  @Input() hasTableSchemaSelect = false;
  @Input() tableAutocompleteDescription = 'Existing tables on the selected schema';
  @Input() hideErrorMessageTable = false;

  operationTypes = [];
  tableNameHintText = null;
  bodyDataOptionsPre = {
    lineWrapping: true,
    lineNumbers: true,
    mode: 'text/x-sql',
    placeholder: 'CREATE TABLE <target_table> (...)',
  };

  bodyDataOptionsPost = {
    lineWrapping: true,
    lineNumbers: true,
    mode: 'text/x-sql',
    placeholder: 'CREATE INDEX ix ON <target_table> (...)',
  };

  hasWarehouse = false;
  hasDatabase = false;
  hasSchema = false;
  hasTable = false;
  hasCreateTable = false;
  hasCreateColumns = false;
  hasOperationType = false;
  hasPreSQL = false;
  hasPostSQL = false;
  OPERATION_TYPE_INSERT = {};
  OPERATION_TYPE_APPEND = {};
  OPERATION_TYPE_TRUNCATE_AND_INSERT = {};
  OPERATION_TYPE_DELETE_AND_INSERT = {};
  OPERATION_TYPE_MERGE = {};
  OPERATION_TYPE_INSERT_OR_UPDATE = {};
  OPERATION_TYPE_MERGE_UPDATE_AND_INSERT = {};
  tablesErrorMessage = '';
  schemasErrorMessage = '';

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.OPERATION_TYPE_INSERT = {
      value: 'append',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.insert`,
      translate: true,
    };
    this.OPERATION_TYPE_APPEND = {
      value: 'append',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.append`,
      translate: true,
    };
    this.OPERATION_TYPE_TRUNCATE_AND_INSERT = {
      value: 'truncate_and_insert',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.truncate_and_insert`,
      translate: true,
    };
    this.OPERATION_TYPE_DELETE_AND_INSERT = {
      value: 'delete_and_insert',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.delete_and_insert`,
      translate: true,
    };
    this.OPERATION_TYPE_MERGE = {
      value: 'merge',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.merge`,
      translate: true,
    };
    this.OPERATION_TYPE_INSERT_OR_UPDATE = {
      value: 'insert_or_update',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.insert_or_update`,
      translate: true,
    };
    this.OPERATION_TYPE_MERGE_UPDATE_AND_INSERT = {
      value: 'merge_update_and_insert',
      text: `database-destination.selects.operation_type.options.${this.component.componentType}.merge_update_and_insert`,
      translate: true,
    };

    // eslint-disable-next-line default-case
    switch (this.component.componentType) {
      case COMPONENT_TYPE.SNOWFLAKE_DESTINATION_COMPONENT:
        this.hasWarehouse = false;
        this.hasDatabase = false;
        this.hasSchema = true;
        this.hasTable = true;
        this.hasCreateTable = true;
        this.hasCreateColumns = true;
        this.hasOperationType = true;
        this.hasPreSQL = true;
        this.hasPostSQL = true;
        this.operationTypes = [
          this.OPERATION_TYPE_APPEND,
          this.OPERATION_TYPE_TRUNCATE_AND_INSERT,
          this.OPERATION_TYPE_DELETE_AND_INSERT,
          this.OPERATION_TYPE_MERGE,
          this.OPERATION_TYPE_MERGE_UPDATE_AND_INSERT,
        ];
        break;

      case COMPONENT_TYPE.DATABASE_DESTINATION_COMPONENT:
      case COMPONENT_TYPE.AMAZON_REDSHIFT_DESTINATION_COMPONENT:
        this.hasSchema = true;
        this.hasTable = true;
        this.hasCreateTable = true;
        this.hasCreateColumns = true;
        this.hasOperationType = true;
        this.hasPreSQL = true;
        this.hasPostSQL = true;
        this.operationTypes = [
          this.OPERATION_TYPE_APPEND,
          this.OPERATION_TYPE_TRUNCATE_AND_INSERT,
          this.OPERATION_TYPE_DELETE_AND_INSERT,
          this.OPERATION_TYPE_MERGE,
          this.OPERATION_TYPE_MERGE_UPDATE_AND_INSERT,
        ];
        break;

      case COMPONENT_TYPE.SPANNER_DESTINATION_COMPONENT:
        this.hasTable = true;
        this.hasCreateTable = true;
        this.hasCreateColumns = true;
        this.hasOperationType = true;
        this.hasPreSQL = true;
        this.hasPostSQL = true;
        this.operationTypes = [
          this.OPERATION_TYPE_APPEND,
          this.OPERATION_TYPE_INSERT_OR_UPDATE,
          this.OPERATION_TYPE_MERGE,
        ];
        break;

      case COMPONENT_TYPE.BIG_QUERY_DESTINATION_COMPONENT:
        this.hasTable = true;
        this.hasCreateTable = true;
        this.hasCreateColumns = true;
        this.hasOperationType = true;
        this.operationTypes = [
          this.OPERATION_TYPE_APPEND,
          this.OPERATION_TYPE_TRUNCATE_AND_INSERT,
          this.OPERATION_TYPE_MERGE,
        ];
        break;

      case COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT:
        this.hasTable = true;
        this.hasOperationType = true;
        this.operationTypes = [this.OPERATION_TYPE_INSERT, this.OPERATION_TYPE_MERGE_UPDATE_AND_INSERT];
        this.tableNameHintText = this.translate.instant(
          'database-destination.labels.mongo_destination_component.table_name_hint',
        );
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const hasConnectionChanged =
      changes.rawComponent?.currentValue?.connection?.id !== changes.rawComponent?.previousValue?.connection?.id;
    const hasFlagChanged = changes.hasTableSchemaSelect?.currentValue && !changes.hasTableSchemaSelect?.previousValue;

    if (hasConnectionChanged && (this.schemasErrorMessage || this.tablesErrorMessage)) {
      this.hasTableSchemaSelect = true;
    }
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }
}
