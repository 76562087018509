import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AccountState } from './account.reducer';

export const selectAccountState = createFeatureSelector<AccountState>('account');

export const selectAccountIdRaw = createSelector(selectAccountState, (state: AccountState) => state.info.account_id);
export const selectAccountIdNumber = createSelector(selectAccountState, (state: AccountState) => state.info.id);
export const selectUserIdRaw = createSelector(selectAccountState, (state: AccountState) => state.user.id);
export const selectIsLoggedRaw = createSelector(selectAccountState, (state: AccountState) => state.isLogged);
export const selectIsLoggedOutRaw = createSelector(selectAccountState, (state: AccountState) => state.isLoggedOut);
export const selectUserEmail = createSelector(selectAccountState, (state: AccountState) => state.user.email);
export const selectUser = createSelector(selectAccountState, (state: AccountState) => state.user);
export const selectAccount = createSelector(selectAccountState, (state: AccountState) => state.info);
export const selectAccountsList = createSelector(selectAccountState, (state: AccountState) => state.accounts);
export const hasUserIntegrateIOID = createSelector(
  selectAccountState,
  (state: AccountState) => state.user && !!state.user.integrateio_id,
);

export const selectAccountPermissions = createSelector(
  selectAccountState,
  (state: AccountState) => state.info.permissions || [],
);

export const selectAccountId = pipe(select(selectAccountIdRaw), filter(Boolean), first());
export const selectIntegrateIOID = pipe(
  select(selectUser),
  filter(
    (user) =>
      user &&
      user.id &&
      !user.settings.sso_enabled &&
      !user.settings.hide_integrateio_migration &&
      !user.integrateio_id,
  ),
  first(),
);
export const selectUserId = pipe(select(selectUserIdRaw), filter(Boolean), first());
export const selectIsLogged = pipe(select(selectIsLoggedRaw), filter(Boolean));
export const selectIsLoggedOut = pipe(select(selectIsLoggedOutRaw), filter(Boolean));

export const selectComponentPreviewerFlag = createSelector(
  selectAccountState,
  (state: AccountState) => state.info.component_previewer_enabled,
);
export const selectWorkspacesFeatureFlag = createSelector(
  selectAccountState,
  (state: AccountState) => state.info.workspaces_feature_enabled,
);
export const selectGCSV2FeatureFlag = createSelector(
  selectAccountState,
  (state: AccountState) => state.info.gcs_v2_enabled,
);
export const selectAIJobExplanationFeatureFlag = createSelector(
  selectAccountState,
  (state: AccountState) => state.info.ai_job_explanation_enabled,
);

export const selectAIErrorSummaryFeatureFlag = createSelector(
  selectAccountState,
  (state: AccountState) => state.info.ai_error_summary_enabled,
);

export const selectDisableAPIKeyFeatureFlag = createSelector(selectAccountState, (state: AccountState) =>
  state.info.permissions.includes('disableApiKey'),
);

export const selectPDFToCSVComponentFeatureFlag = createSelector(selectAccountState, (state: AccountState) =>
  state.info.permissions.includes('pdfToCsvComponent'),
);
