import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectAccount, selectUser } from '../../account/store/account.selectors';
import { SubscriptionsResource } from '../../settings/resources/subscriptions.resource';
import { AuthorizationGuard } from '../services/authorization.guard';
import { NotifyService } from '../services/notify.service';
import { FeedbackDialogComponent } from './feedback-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'xp-onboarding-toolbar',
  template: `
    <div class="onboarding-toolbar" [ngClass]="{ completed: false }">
      <div class="btn-name">
        <span
          >{{ 'onboarding-toolbar.welcome' | translate }}, {{ (user$ | async).name || (user$ | async).email }}!</span
        >
      </div>

      <div class="trial-group" *ngIf="!(account$ | async).billable">
        <div class="text-info">
          {{ 'onboarding-toolbar.trial-days-left' | translate: { days: days } : 'messageformat' }}
        </div>
        <button type="button" class="btn btn-default btn-upgrade" (click)="update()">
          {{ 'onboarding-toolbar.upgrade' | translate }}
        </button>
      </div>

      <div class="right-container">
        <button type="button" class="feedback-button btn btn-info" (click)="openFeedbackDialog()">
          Share Feedback
        </button>

        <apps-toolbar></apps-toolbar>
        <!--        <button class="help-button" (click)="getHelp()">-->
        <!--          <img class="icon" src="assets/img/icons/icon-help.svg" />-->
        <!--          <div class="text-info">-->
        <!--            {{ 'onboarding-toolbar.get-help' | translate }}-->
        <!--          </div>-->
        <!--        </button>-->
      </div>
    </div>
  `,
})
export class OnboardingToolbarComponent implements OnInit {
  days = 0;

  account$ = this.store.select(selectAccount);
  user$ = this.store.select(selectUser);

  constructor(
    private store: Store<AppState>,
    private subscriptionResource: SubscriptionsResource,
    private router: Router,
    private authGuard: AuthorizationGuard,
    private notify: NotifyService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.subscriptionResource
      .get({
        include: 'plan',
      } as any)
      .subscribe({
        next: (subscription) => {
          this.days = subscription.trial_days_left;
        },
        error: () => {
          this.notify.error('An error occurred while loading data.');
        },
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getHelp() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (window['Intercom']) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['Intercom']('showNewMessage');
    }
  }

  update() {
    this.router.navigate([`/${this.authGuard.account.account_id}/settings/account/billing`]);
  }

  openFeedbackDialog() {
    this.dialog.open(FeedbackDialogComponent, {
      data: {},
      position: {
        top: '100px',
      },
      maxWidth: '1000px',
      minWidth: '1000px',
    });
  }
}
