import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-template',
  template: `
    <div class="dialog confirm alert alert-danger text-center">
      <div class="dialog-text">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="danger-icon"
          *ngIf="!data.hideIcon"
        >
          <path
            d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke="#F44B3F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h3>{{ data.title }}</h3>
        <p [innerHTML]="data.hint"></p>
      </div>
      <div class="dialog-btn-container">
        <button class="btn btn-lg btn-default dialog-button" (click)="cancel()">{{ data.cancel }}</button>
        <button class="btn btn-lg btn-primary dialog-button" (click)="mergeFields()">{{ data.no }}</button>
        <button class="btn btn-lg btn-primary dialog-button" (click)="overwriteFields()">
          {{ data.yes }}
        </button>
      </div>
      <div class="dialog-close" (click)="cancel()"></div>
    </div>
  `,
})
export class FieldsAutofillConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FieldsAutofillConfirmationComponent>,
  ) {}

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  overwriteFields() {
    this.dialogRef.close({ action: 'overwrite' });
  }

  mergeFields() {
    this.dialogRef.close({ action: 'merge' });
  }
}
