import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Schema, SelectComponentData } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateRawComponent } from '../../store/component.actions';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'select-editor',
  template: `
    <div class="select-editor">
      <div class="row">
        <div class="col-md-12">
          <label
            ><b>{{ 'select_component.fields_mapping' | translate }}</b></label
          >
          <select-collection
            [records]="rawComponent.fields || []"
            (recordsChange)="onRecordsChange($event)"
            [parentSchemas]="parentSchemas"
            (validityChange)="onValidityChange($event)"
          ></select-collection>
          <small
            >Need help with expressions? See them in action in the
            <a
              (click)="openConsole()"
              data-track-event="click"
              data-category="Help"
              data-action="Contextual Help"
              [attr.data-label]="'help-menu.labels.console' | translate"
              class="new-window"
              target="_blank"
            >
              X-Console
            </a>
          </small>
        </div>
      </div>
      <div class="row">
        <component-previewer [componentId]="rawComponent.id"></component-previewer>
      </div>
    </div>
  `,
})
export class SelectEditorComponent {
  @Input() rawComponent: SelectComponentData;
  @Input() parentSchemas: Schema[];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onRecordsChange(records) {
    this.store.dispatch(updateRawComponent({ rawComponent: { fields: records } }));
  }

  onValidityChange(isValid: boolean) {
    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  openConsole() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.route.parent.snapshot.params.account_id}/console`]),
    );

    window.open(url, '_blank');
  }
}
