import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentTypeItem } from '../../../constants/component_types';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { DatabaseDefinitionComponentData } from './database-definition.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConnectionTypeName } from '../../../connections/connection.models';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  selector: 'database-table-input',
  template: `
    <xp-form-group
      class="col-md-6"
      *ngIf="hasTable"
      [hidden]="queryMode === 'query'"
      [validationDisabled]="queryMode === 'query'"
    >
      <label for="table_name" *ngIf="hasTable && !hasCollection">{{
        'database-definition.form.' + component.componentType + '.labels.table_name' | translate
      }}</label>
      <label for="table_name" *ngIf="hasCollection">{{
        'database-definition.form.' + component.componentType + '.labels.collection' | translate
      }}</label>

      <xp-input
        type="text"
        class="form-control"
        name="table_name"
        id="table_name"
        [ngModel]="rawComponent.table_name"
        (ngModelChange)="onValueChange($event, 'table_name')"
        [autoCompleteOptions]="tableNameOptions"
        [autoCompleteDescription]="tableNameOptions.length ? autoCompleteDescription : ''"
        (autocompleteReachedEnd)="loadMoreTables()"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        [disableSearch]="true"
        [isLazyLoading]="isLazyLoading"
        [placeholder]="
          tablesErrorMessage && hideErrorMessage
            ? 'Table name'
            : ((hasCollection
                ? 'database-definition.form.' + component.componentType + '.placeholders.collection'
                : 'database-definition.form.' +
                  component.componentType +
                  '.placeholders.' +
                  (hasTableSchemaSelect ? 'table_name_rich' : 'table_name')
              ) | translate)
        "
      ></xp-input>
      <i
        class="fa fa-exclamation-circle form-control-info"
        matTooltip="Manually type in to create a new table."
        matTooltipPosition="right"
        matTooltipClass="right"
        *ngIf="showCreateTableTooltip"
      ></i>
      <xp-loader-circle *ngIf="isLoadingTables && isInputFocused"></xp-loader-circle>
      <div *ngIf="tablesErrorMessage && !hideErrorMessage" class="error-message">
        {{ tablesErrorMessage }}
      </div>
      <small *ngIf="tableNameHintText">{{ tableNameHintText }}</small>
    </xp-form-group>
  `,
})
export class DatabaseTableInputComponent implements OnInit, OnChanges {
  @Input() rawComponent: DatabaseDefinitionComponentData;
  @Input() component: ComponentTypeItem;
  @Input() hasCollection = false;
  @Input() hasTable = true;
  @Input() hasTableByAccessMode = true;
  @Input() hasTableSchemaSelect;
  @Input() tableNameHintText;
  @Input() showCreateTableTooltip = false;
  @Input() autoCompleteDescription: string;
  @Input() queryMode: string;
  @Input() hideErrorMessage = false;
  @Output() onTablesLoaded = new EventEmitter<String[]>();

  tableNameOptions = [];
  isLoadingTables = false;
  tablesErrorMessage = '';
  isLazyLoading = false;
  areAllItemsLoaded = false;
  limit = 100;
  offset = 0;
  search = '';
  tableInputSubject: Subject<string> = new Subject();
  isInputFocused = false;
  lastCallWithSearch = false;

  constructor(
    private store: Store<AppState>,
    private connectionItemsResource: ConnectionItemsResource,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const hasConnectionChanged =
      changes.rawComponent?.currentValue?.connection?.id !== changes.rawComponent?.previousValue?.connection?.id &&
      !changes.rawComponent?.firstChange;
    const hasFlagChanged =
      changes.hasTableSchemaSelect?.currentValue &&
      !changes.hasTableSchemaSelect?.previousValue &&
      !changes.hasTableSchemaSelect?.firstChange;
    const hasSchemaName =
      changes.rawComponent?.currentValue?.schema_name !== changes.rawComponent?.previousValue?.schema_name &&
      !changes.rawComponent?.firstChange;

    if (
      (hasConnectionChanged && this.hasTableSchemaSelect && this.rawComponent?.connection?.id) ||
      ((hasFlagChanged || hasSchemaName) && this.rawComponent?.connection?.id)
    ) {
      this.offset = 0;
      this.tableNameOptions = [];
      this.loadTables();
    }

    if ((hasFlagChanged || hasConnectionChanged) && !this.hasTableSchemaSelect) {
      this.tableNameOptions = [];
    }
  }

  ngOnInit() {
    if (this.rawComponent?.connection?.id) {
      this.loadTables();
    }

    this.tableInputSubject.pipe(debounceTime(300)).subscribe((value) => {
      if (this.rawComponent?.connection?.id) {
        this.search = value;
        this.offset = 0;
        this.loadTables();
      }
    });
  }

  loadTables() {
    if (!this.hasTableSchemaSelect) return;
    this.isLoadingTables = true;
    this.tablesErrorMessage = '';
    this.connectionItemsResource
      .databaseTables(this.rawComponent.connection.type, this.rawComponent.connection.id, {
        schema_name: this.rawComponent.schema_name || '',
        limit: this.limit,
        offset: this.offset,
        search: this.search,
      })
      .subscribe({
        next: (response) => {
          let tables: any = response.data;
          if (
            this.rawComponent.connection.type === ConnectionTypeName.bigQuery ||
            this.rawComponent.connection.type === ConnectionTypeName.bigQueryV2
          ) {
            tables = response.raw
              .map((item) => (JSON.parse(item) || {}).table_name)
              .filter(Boolean)
              .map(Array);
          }

          this.isLoadingTables = false;
          if (this.isLazyLoading) {
            this.isLazyLoading = false;
            this.tableNameOptions = [...this.tableNameOptions, ...tables.map((table) => table[0])];
          } else {
            this.tableNameOptions = tables.map((table) => table[0]);
          }
          this.areAllItemsLoaded = tables.length < this.limit;
          if (this.search) {
            this.lastCallWithSearch = true;
          }

          this.onTablesLoaded.emit(tables.map((table) => table[0]));
        },
        error: ({ error }) => {
          this.isLoadingTables = false;
          this.tablesErrorMessage = `There was an issue while fetching tables list: ${error?.error_message}`;
          this.isLazyLoading = false;
        },
      });
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));

    if (value !== this.rawComponent.table_name && this.isInputFocused) {
      this.tableInputSubject.next(value);
    }
  }

  loadMoreTables() {
    if (this.areAllItemsLoaded) {
      return;
    }

    this.isLazyLoading = true;
    this.offset += this.limit;

    this.loadTables();
  }

  onInputBlur() {
    this.isInputFocused = false;
    this.search = '';
    this.offset = 0;
  }

  onInputFocus() {
    this.isInputFocused = true;
    if (!this.rawComponent?.connection?.id) {
      return;
    }

    if (this.tableNameOptions.length < this.limit && !this.areAllItemsLoaded) {
      this.loadTables();
    }

    if (this.lastCallWithSearch) {
      this.loadTables();
      this.lastCallWithSearch = false;
    }
  }
}
