import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Router } from '@angular/router';
import { PermissionsService } from '../../common/services/permissions.service';
import { Job, JobStatus } from '../jobs.models';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { ClusterTypes, Creator } from '../../clusters/clusters.models';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { removeJob } from '../store/jobs.actions';
import { Package } from '../../packages/package.models';
import { AuthorizationGuard } from '../../common/services/authorization.guard';
import { CustomMiddleClickEvent } from '../../common/helper/global-types.helper';

function isJobRunning(item: Job): boolean {
  if (!item || !item.status) {
    return false;
  }
  return [JobStatus.stopping, JobStatus.pending_stoppage, JobStatus.running, JobStatus.queued].includes(item.status);
}

@Component({
  selector: 'job-list-item',
  template: `
    <div class="generic-list-item slider-animation jobs" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="job-list item {{ item.status }}" id="job-{{ item.id }}">
        <div class="job">
          <common-icon iconId="icon-job"></common-icon>

          <div class="job-info-container">
            <span class="job-item"
              ><a [xpHref]="'/jobs/' + item.id" (click)="openJobDetailsModal(item, $event)">{{ itemId }}</a></span
            >
            <span class="job-date" *ngIf="creator.type === 'User'">
              <span
                class="date-tooltip"
                [matTooltip]="item.created_at | xpDateUTC"
                matTooltipPosition="above"
                matTooltipClass="above wide"
                >{{ item.created_at | xpPrettyDate }}</span
              >
              <br />by
              <a (click)="goToCreator(creator, $event)" class="owner">{{ creator.display_name | xpLengthCheck: 22 }}</a>
            </span>
            <span class="job-date" *ngIf="creator.type === 'Schedule'">
              <span
                class="date-tooltip"
                [matTooltip]="item.created_at | xpDateUTC"
                matTooltipPosition="above"
                matTooltipClass="above wide"
                >{{ item.created_at | xpPrettyDate }}</span
              >
              <br />by
              <a
                (click)="goToCreator(creator, $event)"
                (auxclick)="goToCreator(creator, { isMiddleClick: true })"
                class="owner"
                >{{ creator.display_name | xpLengthCheck: 22 }}
              </a>
            </span>
          </div>
        </div>
        <div class="package">
          <div class="package-icon">
            <common-icon iconId="icon-workflow" *ngIf="item.package.flow_type === 'workflow'"></common-icon>
            <common-icon iconId="icon-package" *ngIf="item.package.flow_type === 'dataflow'"></common-icon>
          </div>
          <div class="package-name">
            <a
              [xpHref]="'/packages/' + item.package.id + '/edit'"
              (click)="goToPackage(item.package, $event)"
              (auxclick)="goToPackage(item.package, { isMiddleClick: true })"
              [matTooltip]="item.package.name"
              matTooltipPosition="right"
              matTooltipClass="right wide"
              [innerHTML]="item.package.name | xpHighLight: searchValue"
            ></a>
            <a
              [xpHref]="'/packages/' + item.package.id + '/versions/' + item.package.version"
              *ngxPermissionsOnly="'versionControl'"
              class="package-version"
              [matTooltip]="'Click to open this version of the package'"
              matTooltipPosition="right"
              matTooltipClass="right wide"
              (click)="goToPackageVersion(item.package, $event)"
              (auxclick)="goToPackageVersion(item.package, { isMiddleClick: true })"
            >
              Version {{ item.package.package_version }}
            </a>
          </div>
        </div>
        <div class="cluster" *ngIf="!item.cluster">
          <span>No cluster</span>
        </div>
        <div class="cluster" *ngIf="item.cluster">
          <strong
            ><span [matTooltip]="item.cluster.name" matTooltipPosition="right" matTooltipClass="right wide">{{
              item.cluster.name | xpLengthCheck: 32
            }}</span></strong
          >
          <p>
            <span>{{ 'cluster-view.labels.statuses.' + item.cluster.status | translate }}</span
            ><span class="left-separator">
              <span *ngIf="item.cluster.type === ClusterTypes.production" [ngClass]="item.cluster.type">{{
                'job.generic-list.item.labels.nodes' | translate: { count: item.cluster.nodes }
              }}</span>
              <span *ngIf="item.cluster.type === ClusterTypes.sandbox" [ngClass]="item.cluster.type">{{
                'cluster.generic-list.item.labels.sandbox' | translate
              }}</span>
            </span>
          </p>
        </div>
        <div class="runtime">
          <p *ngIf="item.started_at">{{ runTimeInSeconds | xpFormatTime }}</p>
          <p *ngIf="!item.started_at">---</p>
          <p class="small" *ngIf="item.created_at">
            {{ 'job.generic-list.item.labels.created' | translate }}
            <span
              class="date-tooltip date-value full-date"
              [matTooltip]="item.created_at | xpDateUTC"
              matTooltipPosition="above"
              matTooltipClass="above wide"
            >
              {{ item.created_at | xpCalendar }}
            </span>
            <span class="date-tooltip date-value short-date">
              {{ item.created_at | xpDateWithTime }}
            </span>
          </p>
          <p class="small" *ngIf="item.started_at">
            {{ 'job.generic-list.item.labels.started' | translate }}
            <span
              class="date-tooltip date-value full-date"
              [matTooltip]="item.started_at | xpDateUTC"
              matTooltipPosition="above"
              matTooltipClass="above wide"
            >
              {{ item.started_at | xpCalendar }}
            </span>
            <span class="date-tooltip date-value short-date">
              {{ item.started_at | xpDateWithTime }}
            </span>
          </p>
          <p class="small" *ngIf="item.completed_at">
            {{ 'job.generic-list.item.labels.completed' | translate }}
            <span
              class="date-tooltip date-value full-date"
              [matTooltip]="item.completed_at | xpDateUTC"
              matTooltipPosition="above"
              matTooltipClass="above wide"
            >
              {{ item.completed_at | xpCalendar }}
            </span>
            <span class="date-tooltip date-value short-date">
              {{ item.completed_at | xpDateWithTime }}
            </span>
          </p>
        </div>
        <div class="status">
          <div [ngClass]="item.status" class="status-box" *ngIf="item.package.flow_type === 'dataflow'">
            <div class="job-status-name">{{ 'job.generic-list.item.labels.' + item.status | translate }}</div>
            <div class="job-status-indicator-container">
              <div class="jobs-status-indicator-background">
                <div class="jobs-status-indicator" [style]="{ width: percentValue + '%' }"></div>
              </div>

              <div class="job-status-percent">
                <xp-number-tracker
                  [isFinished]="item.status === JobStatus.completed"
                  [end]="statusPercent"
                ></xp-number-tracker>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="status-label" [ngClass]="item.status" *ngIf="item.package.flow_type === 'workflow'">
            {{ 'job.generic-list.item.workflow_labels.' + item.status | translate }}
          </div>
        </div>
        <div class="details">
          <div class="status-button" [ngClass]="item.status" (click)="openJobDetailsModal(item, $event)">
            <svg class="icon">
              <use class="" xmlns:xlink="http://www.w3.org/1999/xlink" href="#icon-info"></use>
            </svg>
            {{ 'job.generic-list.item.labels.view_details' | translate }}
          </div>
        </div>
        <div
          [hidden]="hideDropDown"
          class="dropdown xp-dropdown-full"
          *ngxPermissionsOnly="'updateJob'"
          [ngClass]="{
            'dropdown-disable': !(
              item.status === JobStatus.running ||
              item.status === JobStatus.pending ||
              item.status === JobStatus.queued ||
              item.status === JobStatus.idle
            ),
          }"
        >
          <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
        </div>
        <mat-menu #dropdown="matMenu" class="full-dropdown-menu">
          <li mat-menu-item (click)="stopJob(item)">
            <span class="text-danger">{{ 'job.generic-list.item.actions.stop' | translate }}</span>
          </li>
        </mat-menu>
        <div
          class="status-progress-bar"
          [style]="'width:' + percentValueBottomProgressBar + '%;'"
          [ngClass]="{
            'progress-bar-primary': item.status === JobStatus.running,
            'progress-bar-danger': item.status === JobStatus.failed,
            'progress-bar-success': item.status === JobStatus.completed,
            'progress-bar-warning': [
              JobStatus.idle,
              JobStatus.pending,
              JobStatus.stopped,
              JobStatus.stopping,
              JobStatus.pending_stoppage,
              JobStatus.queued,
            ].includes(item.status),
          }"
        ></div>
      </div>
    </div>
  `,
})
export class JobListItemComponent implements OnChanges, OnDestroy {
  @Input() item: Job;
  @Input() searchValue: string;
  @Input() hideDropDown = false;
  @Output() openJobDetails = new EventEmitter<Job>();

  JobStatus = JobStatus;
  previousProgress = 0;
  previousStatus = '';
  statusPercent = 0;
  counterIntervalRefRuntime: ReturnType<typeof setInterval>;
  runTimeInSeconds: number;
  ClusterTypes = ClusterTypes;

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private router: Router,
    private authGuard: AuthorizationGuard,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.updateRuntime(changes.item.currentValue);
    }
  }

  get percentValue(): number {
    if (this.item.status === JobStatus.failed) {
      return 10;
    }

    return this.statusPercent || 10;
  }

  get percentValueBottomProgressBar(): number {
    if (this.item.status === JobStatus.failed) {
      return 100;
    }

    return this.statusPercent || 0;
  }

  updateRuntime(item: Job) {
    if (!item) {
      this.updateProgress(0);
      this.previousProgress = 0;
      this.previousStatus = '';
      this.runTimeInSeconds = 0;
      return;
    }
    if (isJobRunning(item)) {
      this.stopCounter();
      this.runCounter();
      if (item.progress > this.previousProgress || item.status !== this.previousStatus) {
        this.updateProgress(item.progress);
        this.previousProgress = item.progress;
        this.previousStatus = item.status;
      }
    } else {
      this.stopCounter();
      this.updateProgress(item.progress);
      this.previousProgress = item.progress;
      this.previousStatus = item.status;
      this.runTimeInSeconds = this.item.runtime_in_seconds;
    }
  }

  runCounter() {
    this.counterIntervalRefRuntime = setInterval(() => {
      const startTime = this.item.started_at ? moment(this.item.started_at) : moment();
      const endTime = this.item.completed_at ? moment(this.item.completed_at) : moment();
      this.runTimeInSeconds = endTime.diff(startTime, 'seconds');
    }, 1000);
  }

  stopCounter() {
    clearInterval(this.counterIntervalRefRuntime);
  }

  updateProgress(from: number = 0) {
    this.statusPercent = from * 100;
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  openJobDetailsModal(item: Job, event: Event): void {
    event.preventDefault();
    this.openJobDetails.emit(item);
  }

  get creator(): Creator {
    return this.item.creator || ({} as Creator);
  }

  goToCreator(creator: Creator, event: MouseEvent | CustomMiddleClickEvent): void {
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent?.metaKey || mouseEvent?.ctrlKey || customEvent?.isMiddleClick;
    let url = '';

    if (creator.type === 'Schedule') {
      url = `/${accountId}/schedules/${creator.id}/edit`;
    }

    if (creator.type === 'User') {
      url = `/${accountId}/settings/account/members/${creator.id}`;
    }

    if (openInNewTab) {
      const absoluteUrl = this.router.serializeUrl(this.router.createUrlTree([url]));

      window.open(absoluteUrl, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }

  goToPackage(packageItem: Package, event: MouseEvent | CustomMiddleClickEvent): void {
    (event as Event).preventDefault();
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent?.metaKey || mouseEvent?.ctrlKey || customEvent?.isMiddleClick;

    if (openInNewTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/${accountId}/packages/${packageItem.id}/edit`]),
      );

      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${accountId}/packages/${packageItem.id}/edit`]);
    }
  }

  goToPackageVersion(packageItem: Package, event: MouseEvent | CustomMiddleClickEvent): void {
    (event as Event).preventDefault();
    const accountId = this.authGuard.account?.account_id;
    const mouseEvent = event as MouseEvent;
    const customEvent = event as CustomMiddleClickEvent;
    const openInNewTab = mouseEvent?.metaKey || mouseEvent?.ctrlKey || customEvent?.isMiddleClick;

    if (openInNewTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/${accountId}/packages/${packageItem.id}/versions/${packageItem.version}`]),
      );

      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${accountId}/packages/${packageItem.id}/versions/${packageItem.version}`]);
    }
  }

  stopJob(job: Job) {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('job.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('job.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('job.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('job.generic-list.item.modals.confirm.no'),
      item: job,
      itemType: ItemType.job,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeJob({ jobId: job.id }));
      }
    });
  }

  get itemId(): string {
    return String(this.item.id);
  }

  get itemExecutionTimeZone(): string {
    const timezone = this.item.execution_time_zone;
    if (!timezone) return 'UTC';

    const offset = moment.tz(timezone).format('Z');
    const formattedOffset = offset.replace(/^(-?)(\d{2})(\d{2})$/, '$1$2:$3');
    return `GMT${formattedOffset}`;
  }

  ngOnDestroy() {
    this.stopCounter();
  }
}
