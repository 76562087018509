import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngrx/store';

import { updateComponent } from '../../../store/component.actions';
import { AppState } from '../../../../store';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { Schema } from '../../../package.models';
import { ConfirmationDialogService } from '../../../../common/services/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

function autoGenerateAlias(name: string): string {
  if (name) {
    const nonDashAlphanumerics = /[^a-zA-Z\d!_]/g;
    const hasNonDashAlphanumerics =
      name.match(nonDashAlphanumerics) !== null && name.match(nonDashAlphanumerics).length > 0;

    // replace all non-alphanumeric characters with underscore
    let alias = name.replace(/[^a-zA-Z\d]/g, '_');

    if (hasNonDashAlphanumerics) {
      // replace consecutive instances of underscores with a single underscore
      alias = alias.replace(/_{2,}/g, '_');
    }
    // take the first 50 characters and trim them from beginning or trailing underscores
    alias = alias.replace(/^_+|_+$/g, '');

    return alias;
  }
  return '';
}

@Component({
  selector: 'select-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (save)="save($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['expression', 'alias']"
      duplicationValidationProp="projected_name"
      duplicationValidationPropName="Alias"
    >
      <ng-template templateName="expression" let-item>
        <xp-field-expression-editor
          [value]="item.record.name"
          [schema]="parentSchemas[0]"
          [fields]="(parentSchemas[0] || {}).fields || []"
          [index]="item.index"
          [focusedProp]="item.focusedProp"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'name')"
        ></xp-field-expression-editor>
      </ng-template>
      <ng-template templateName="expression-header" let-item>
        {{ 'fields-collection.headers.field-expression-editor' | translate }}
      </ng-template>

      <ng-template templateName="alias" let-item>
        <xp-alias-editor
          [value]="item.record.projected_name"
          [index]="item.index"
          propName="projected_name"
          [focusedProp]="item.focusedProp"
          [isDuplicateError]="item.record.isDuplicateError"
          class="fields-collection-editor"
          (fieldChange)="onFieldChange($event, item.record, 'projected_name')"
        ></xp-alias-editor>
      </ng-template>
      <ng-template templateName="alias-header" let-item>
        <xp-alias-editor-title [title]="'fields-collection.headers.alias-editor' | translate"></xp-alias-editor-title>
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
})
export class SelectCollectionComponent implements OnInit {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() active: boolean;
  @Input() collectionSettings: CollectionSettings;
  @Input() parentSchemas: Schema[];
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor(
    private store: Store<AppState>,
    private confirmationDialog: ConfirmationDialogService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 20,
      emptyRecord: { name: '', projected_name: '', FC_pristine: true },
      parentSchemas: this.parentSchemas,
      autoGenerateFn: this.autoGenerateAliasAndUpdateRecords.bind(this),
      autoFillFns: [
        {
          text: 'Auto-fill',
          func: this.autoFill.bind(this),
          isObservable: true,
        },
      ],
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  autoFill(): Observable<DesignerSchemaFieldI[]> {
    if (this.recordsCopy.length && this.recordsCopy.some((record) => record.projected_name)) {
      const dialogRef = this.confirmationDialog.openFieldsAutofillConfirmationDialog({
        title: 'What do you want to do with the existing fields?',
        hint: 'You have existing fields in this collection. Clicking "Overwrite all fields" will overwrite all existing fields with the new ones. Clicking "Merge with existing fields" will merge the new fields with the existing ones. Clicking "Cancel" will cancel the operation.',
        yes: 'Overwrite all fields',
        no: 'Merge with existing fields',
        cancel: 'Cancel',
      });
      return dialogRef.afterClosed().pipe(
        take(1),
        map((action) => {
          if (action.action === 'cancel') {
            return this.recordsCopy;
          }

          let newRecords = this.generateFields();

          if (action.action === 'overwrite') {
            this.onRecordChange({ records: newRecords });
            this.recordsCopy = newRecords;
            return newRecords;
          }

          if (action.action === 'merge') {
            newRecords = [...this.recordsCopy, ...newRecords];
            this.onRecordChange({ records: newRecords });
            this.recordsCopy = newRecords;
            return newRecords;
          }
        }),
      );
    } else {
      return of(this.generateFields()).pipe(
        take(1),
        map((newRecords) => {
          this.onRecordChange({ records: newRecords });
          this.recordsCopy = newRecords;
          return newRecords;
        }),
      );
    }
  }

  generateFields() {
    if (!this.parentSchemas[0]) {
      return [this.collectionSettings.emptyRecord];
    }

    let newRecords = [];

    if (this.parentSchemas && this.parentSchemas[0] && this.parentSchemas[0].fields) {
      newRecords = [];
    }

    newRecords = (this.parentSchemas[0].fields || []).map((field) => ({
      name: field.name,
      projected_name: autoGenerateAlias(field.name),
      id: uuidv4(),
    }));

    return newRecords;
  }

  autoGenerateAliasAndUpdateRecords(record: DesignerSchemaFieldI) {
    const alias = autoGenerateAlias(record.name);

    if (alias) {
      this.recordsCopy = this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            projected_name: alias,
            alias,
          };
        }
        return item;
      });
    }

    this.onRecordChange({ records: this.recordsCopy });
  }

  save(records: DesignerSchemaFieldI[]) {
    this.records = records.map((record) => {
      return { name: record.name, projected_name: record.projected_name };
    });
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fields = records.map(({ name, projected_name }) => ({ name, projected_name }));
    this.store.dispatch(updateComponent({ component: { fields } }));
    this.recordsChange.emit(fields);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    this.recordsCopy = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });

    this.onRecordChange({
      records: this.recordsCopy,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
